import "../App.css";
import "./crops.css";

export default function TimeRates() {
  return (
    <div>
      <h4>معدل تذبذب الأسعار خلال الفترات: </h4>
      <section className="rates">
        <div className="rate-div rate">
          <p>اليوم</p>
          <p>1%</p>
        </div>
        <div className="rate-div">
          <p>أسبوع</p>
          <p>30%</p>
        </div>
        <div className="rate-div">
          <p>شهر</p>
          <p>44%</p>
        </div>
        <div className="rate-div">
          <p>6 شهور</p>
          <p>87%</p>
        </div>
        <div className="rate-div">
          <p>سنة</p>
          <p>140%</p>
        </div>
      </section>
    </div>
  );
}
