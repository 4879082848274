import "../App.css";
import "../crops/crops.css";

export default function HistoricalData() {
  return (
    <div className="crop-data main-table">
      <h2>البيانات التاريخية للفول السوداني</h2>
      <table>
        <tr>
          <th>التاريخ</th>
          <th>السعر</th>
          <th>معدل التغير اليومي</th>
        </tr>
        <tr>
          <td>22/05/2023</td>
          <td>400,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>21/05/2023</td>
          <td>400,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>20/05/2023</td>
          <td>760,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>13/05/2023</td>
          <td>340,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>19/05/2023</td>
          <td>410,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>18/05/2023</td>
          <td>290,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>17/05/2023</td>
          <td>560,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>16/05/2023</td>
          <td>466,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>15/05/2023</td>
          <td>453,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
        <tr>
          <td>14/05/2023</td>
          <td>420,000 جنيه / للطن</td>
          <td className="green">15%+</td>
        </tr>
      </table>
    </div>
  );
}
