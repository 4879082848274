import "../App.css";
import "./crops.css";
import { Link } from "react-router-dom";

export default function CropBars({ crop }) {
  return (
    <nav className="crop-bars">
      <ul>
        <li>
          {crop == 1 ? (
            <Link id="gr" to="/crops">
              {" "}
              ملخص
            </Link>
          ) : (
            <Link to="/crops"> ملخص</Link>
          )}
        </li>
        <li>
          {crop == 2 ? (
            <Link id="gr" to="/crops-data">
              البيانات التاريخية
            </Link>
          ) : (
            <Link to="/crops-data">البيانات التاريخية</Link>
          )}
        </li>
        <li>
          <Link to="/crops-data">تفاصيل عن السوق</Link>
        </li>
        <li>
          <Link to="/crops-data">تفاصيل عن الانتاج</Link>
        </li>
        <li>
          <Link to="/crops-data">المناخ والطقس</Link>
        </li>
      </ul>
    </nav>
  );
}
