import "../App.css";
import "./crops.css";
import CropsPrices from "./CropsPrices";
import StatesChartPie from "./StatesChartPie.tsx";

const data = [
  { name: "غرب دارفور", value: 90 },
  { name: "الجزيرة", value: 12 },
  { name: "النيل الأبيض", value: 34 },
  { name: "شمال كردفان", value: 53 },
  { name: "غرب كردفان", value: 58 },
];

export default function CropsDetail({ width = 600, height = 400 }) {
  return (
    <section className="crops-detail-content">
      <div className="crops-detail-rows row-one">
        <div className="crop-box crops-brief">
          <h2>مؤجز مبسط:</h2>
          <p>
            الفول السوداني هو نبات حولي من فصيلة البقليات, وموطنه الأصلي المناطق
            الاستوائية من أميركا الجنوبي.
          </p>
          <p>
            غالبية انتاج السودان من المحصول يأتي من ولاية دارفور, ويمثل الفول
            السوداني 12% من صادرات السودان الزراعية.
          </p>
          <p>
            الفول يدخل في صناعة الدكوة والشكلاتة والحلو والصابون, بحيث أن 70% من
            الناتج يتم تحويله الى منتجات ثانوية.
          </p>
        </div>
        <div className="crop-box crops-states">
          <h2>مناطق الزراعة </h2>
          <StatesChartPie data={data} width={width} height={height} />
        </div>
        <div className="crop-box crop-practices">
          <h2>كيفية الزراعة</h2>
          <p>
            وسائل الري المستخدمة للمحصول هي فلان, و نوع التربة اللي يفضل
            استخدامها من نوع معين ولذا نلاحظ غالبية الانتاج يأتي من غرب دارفور
            بسبب الظروف الطبيعية المناسبة
          </p>
          <p>
            طول فترة الزراعة تستغرق 4 شهور عادة, و يتم زراعة الفول السوداني مرة
            بالسنة لأنه يزرع بموسم الخريفو يتم الحصاد في شهر أكتوبر.
          </p>
        </div>
      </div>
      <div className="crops-detail-rows row-two">
        <div className="crop-box crops-prices">
          <h2>متابعة الأسعار</h2>
          <p>السعر العالمي: 1300 الف للطن.</p>
          <p>سعر سوق أم درمان بالجملة: 420 الف للطن</p>
          <p>سعر سوق نيالا: 300 الف للطن.</p>
          <p>سعر المزارع مباشرة: 210 الف للطن.</p>
        </div>
        <div className="crops-area-prices">
          <h2>رسم بياني يومي للسوق</h2>
          <CropsPrices />
        </div>
        <div className="crop-box ">
          <h2>الطقس والمناخ</h2>
          <p>
            الجو مسخن شديد و موية ماف بولاية النيل الأبيض حاليا, وحاليا دا منتصف
            موسم الخريف, و المناخ المثالي جو معتدل و موية متوفرة.
          </p>
        </div>
        <div className="crop-box ">
          <h2>توقعات الموسم القادم</h2>
          <p>
            فساد 45% من الموسم بسبب الفيضانات التي حدثت في 5 أغسطس من العام
            الحالي.
          </p>
          <p>
            صعوبة نقل الناتج القادم من غرب السودان بسبب النزاعات القبلية
            بالمنطقة والتي شلت حركة النقل تماما.
          </p>
          <p>
            طلب ضخم على ناتج المحصول القادم من ولاية النيل الأبيض مع أنه يشكل
            25% من حجم الحصاد فقط بسبب النزاع بغرب السودان.
          </p>
        </div>
      </div>
    </section>
  );
}
