import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    سوق_نيالا: 4000,
    سوق_أم_درمان: 2400,
    السوق_العالمي: 2400,
  },
  {
    name: "Page B",
    سوق_نيالا: 3000,
    سوق_أم_درمان: 1398,
    السوق_العالمي: 2210,
  },
  {
    name: "Page C",
    سوق_نيالا: 2000,
    سوق_أم_درمان: 9800,
    السوق_العالمي: 2290,
  },
  {
    name: "Page D",
    سوق_نيالا: 2780,
    سوق_أم_درمان: 3908,
    السوق_العالمي: 2000,
  },
  {
    name: "Page E",
    سوق_نيالا: 1890,
    سوق_أم_درمان: 4800,
    السوق_العالمي: 2181,
  },
  {
    name: "Page F",
    سوق_نيالا: 2390,
    سوق_أم_درمان: 3800,
    السوق_العالمي: 2500,
  },
  {
    name: "Page G",
    سوق_نيالا: 3490,
    سوق_أم_درمان: 4300,
    السوق_العالمي: 2100,
  },
];

export default function CropsPrices() {
  const demoUrl = "https://codesandbox.io/s/stacked-area-chart-ix341";

  return (
    <div className="chart-area">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={250}
          height={200}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="سوق_نيالا"
            stackId="1"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="سوق_أم_درمان"
            stackId="1"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
          <Area
            type="monotone"
            dataKey="السوق_العالمي"
            stackId="1"
            stroke="#ffc658"
            fill="#ffc658"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
