import React, { useRef, useEffect } from "react";
import "./App.css";
import TitleImg from "./wheat.png";
import { Link } from "react-router-dom";
import SearchIcon from "./search-icon.png";

export default function Headers() {
  const searchTitleRef = useRef(null);

  const displaySearch = () => {
    const searchTitle = searchTitleRef.current;
    const computedStyle = window.getComputedStyle(searchTitle);
    const displayValue = computedStyle.getPropertyValue("display");

    if (displayValue === "flex") {
      searchTitle.style.display = "none";
    } else if (displayValue === "none") {
      searchTitle.style.display = "flex";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const searchTitle = searchTitleRef.current;
      const searchIcon = document.getElementById("search-icon");

      if (
        searchTitle &&
        !searchTitle.contains(event.target) &&
        !searchIcon.contains(event.target)
      ) {
        searchTitle.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header>
      <Link to="/">
        <div className="title">
          <img src={TitleImg} className="title-img" alt="Title" />
          <h1>ديوان المحاصيل</h1>
        </div>
      </Link>
      <nav>
        <ul>
          <li className="nav-li">
            <Link to="#">بيانات</Link>
          </li>
          <li className="nav-li">
            <Link to="#">من نحن</Link>
          </li>
          <li>
            <Link to="#">تواصل معنا</Link>
          </li>
          <img
            id="search-icon"
            src={SearchIcon}
            className="search-icon"
            alt="Search Icon"
            onClick={displaySearch}
          />
          <div id="search-title-div" ref={searchTitleRef}>
            <input
              className="search-title"
              type="text"
              placeholder="أبحث عن السلعة المرادة.."
            />
            <button className="search-submit">بحث</button>
          </div>
        </ul>
      </nav>
    </header>
  );
}
