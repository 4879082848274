import "./App.css";

export default function Search() {
  return (
    <div className="search-filter">
      <h3>للتوسع بالبحث عن المحاصيل</h3>
      <input type="text" placeholder="أبحث عن السلعة المرادة.." />
      <div className="line"></div>
    </div>
  );
}
