import "./App.css";

export default function Update() {
  return (
    <section className="update box">
      <marquee direction="right" width="90%">
        <div className="update-text">
          <p>
            <span>سعر الفول السوداني :</span> 400 الف جنيه
          </p>
          <p>
            <span>سعر الذهب :</span> 19 الف جنيه
          </p>
          <p>
            <span>سعر الفول المصري :</span> 900 الف جنيه
          </p>
          <p>
            <span>سعر النحاس :</span> 6 الف جنيه
          </p>
        </div>
      </marquee>
    </section>
  );
}
