import "../App.css";
import "./crops.css";

export default function TopInfo() {
  return (
    <section className="top-info">
      <div className="top-info-title">
        <h1>الفول السوداني</h1>
      </div>
      <div>
        <p>
          <span className="small">سعر اليوم:</span> 400 الف جنيه
        </p>
        <p>
          <span className="small">معدل التغير اليومي:</span> هبوط 20 الف{" "}
          {`(%20-)`}
        </p>
        <p>
          <span className="small">أعلى سعر خلال العام:</span> 580 الف جنيه
        </p>
        <p>
          <span className="small">أدنى سعر خلال العام: </span> 320 الف جنيه
        </p>
        <p>
          <span className="small">أخر تحديث للبيانات: </span> قبل 3 ساعات
        </p>
      </div>
    </section>
  );
}
