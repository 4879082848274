import "./App.css";
import CurrenciesTable from "./Currencies-Table";
import Tools from "./Explore-tools";
import Footer from "./Footer";
import Header from "./Header";
import MainTable from "./Main-Table";
import Search from "./search-filter";
import Update from "./update-line";

function App() {
  return (
    <div className="App">
      <Header></Header>

      <Update></Update>
      <div className="main">
        <section className="right-main">
          <MainTable></MainTable>
          <CurrenciesTable></CurrenciesTable>
        </section>
        <section className="left-main">
          <div className="left-container">
            <Search></Search>
            <Tools></Tools>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
