import "../App.css";
import "../crops/crops.css";
import Tools from "../Explore-tools";
import Header from "../Header";
import Search from "../search-filter";
import Update from "../update-line";
import TopInfo from "../crops/TopInfo";
import CropBars from "../crops/CropBars";
import Footer from "../Footer";
import HistoricalData from "./Historical-Data";
import Chart from "../crops/Chart";

function CropsDataApp() {
  let crop = 2;
  return (
    <div className="App">
      <Header></Header>

      <Update></Update>
      <div className="crops-container">
        <section className="main-crops">
          <TopInfo />
          <Chart />
        </section>
        <div className="crops-detail">
          <div className="crops">
            <CropBars crop={crop} />
            <HistoricalData />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CropsDataApp;
