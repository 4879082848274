import "./App.css";
import { Link } from "react-router-dom";

export default function MainTable() {
  return (
    <div className="main-table">
      <h2>أسعار المحاصيل لليوم</h2>
      <table>
        <tr>
          <th>المنتج</th>
          <th>سعر اليوم</th>
          <th>معدل التغير اليومي</th>
          <th>أعلى سعر خلال العام</th>
          <th>أدنى سعر خلال العام</th>
          <th>أخر تحديث للبيانات</th>
        </tr>
        <tr>
          <td>
            <Link to="/crops">الفول السوداني</Link>
          </td>
          <td>400,000 جنيه / للطن</td>
          <td className="green">15%+</td>
          <td>350,000 جنيه / للطن</td>
          <td>650,000 جنيه / للطن</td>
          <td>قبل 5 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>فول الصويا</td>
          <td>400,000 جنيه / للطن</td>
          <td className="green">15%+</td>
          <td>350,000 جنيه / للطن</td>
          <td>650,000 جنيه / للطن</td>
          <td>قبل 5 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
        <tr>
          <td>السمسم الأبيض</td>
          <td>100,000 جنيه / للطن</td>
          <td className="red">3.5%-</td>
          <td>150,000 جنيه / للطن</td>
          <td>350,000 جنيه / للطن</td>
          <td>قبل 8 ساعات</td>
        </tr>
      </table>
    </div>
  );
}
