import "./App.css";

export default function CurrenciesTable() {
  return (
    <div className="main-table">
      <h2>أسعار العملات الأجنبية لليوم</h2>
      <table>
        <tr>
          <th>العملة</th>
          <th>سعر اليوم</th>
          <th>معدل التغير اليومي</th>
          <th>أعلى سعر خلال العام</th>
          <th>أدنى سعر خلال العام</th>
          <th>أخر تحديث للبيانات</th>
        </tr>
        <tr>
          <td>الدولار الأميركي</td>
          <td>570 جنيه</td>
          <td className="green">15%+</td>
          <td>600 جنيه</td>
          <td>520 جنيه</td>
          <td>قبل 10 ساعات</td>
        </tr>
        <tr>
          <td>الجنيه الاسترليني</td>
          <td>1200 جنيه</td>
          <td className="red">3.5%-</td>
          <td>1500 جنيه</td>
          <td>800 جنيه</td>
          <td>قبل 3 ساعات </td>
        </tr>
        <tr>
          <td>الريال السعودي</td>
          <td>200 جنيه</td>
          <td className="green">15%+</td>
          <td>280 جنيه</td>
          <td>180 جنيه</td>
          <td>قبل 5 ساعات</td>
        </tr>
      </table>
    </div>
  );
}
