import "./App.css";

export default function Tools() {
  return (
    <div className="explore-tools">
      <h3>خدمات الموقع</h3>
      <div className="tool-box">
        <a href="#">جرس تنبيهات</a>
        <p> خدمة أرسال تنبيهات دقيقة و استقبال اشعارات للبيانات</p>
      </div>
      <div className="tool-box">
        <a href="#">أرشيف بيانات</a>
        <p> تحميل نسخ من التقريرات والبيانات </p>
      </div>
      <div className="tool-box">
        <a href="#">الأسواق العالمية</a>
        <p> مقارنة الأسواق والمحاصيل المحلية مع السوق العالمي</p>
      </div>
    </div>
  );
}
