import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import CropsApp from "./crops/CropsApp";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CropsDataApp from "./crops-data/CropsDataApp";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "crops",
    element: <CropsApp />,
  },
  {
    path: "crops-data",
    element: <CropsDataApp />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
